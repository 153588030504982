<template>
	<el-dialog 
		top="1vh" 
		width="1050px" 
		:title="upl_title" 
		append-to-body 
		custom-class="el-dialog-s" 
		:visible.sync="is_show_in_page"
	>
		<el-form class="form_ordinary" label-width="100px">
			
			<div class="big_tit" style="margin-top:0px;display:flex;justify-content: space-between;">
				<div>驾驶证信息</div>
				<div>
					<el-button type="primary" size="mini" style="margin-left:20px" @click="show_dl">显示驾驶证</el-button>
					<el-button type="primary" size="mini" style="margin-left:20px" @click="ocr_dl">识别驾驶证</el-button>
				</div>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="司机姓名" style="width:388px">
					<el-input class="el_inner_width" v-model="form.app_user_info.name" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="身份证号" style="width:588px">
					<el-input class="el_inner_width" v-model="form.app_user_info.id_card_num" disabled></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="准驾车型" style="width:388px">
					<el-select class="el_inner_width" v-model="form.dl_class">
						<el-option v-for="(item,index) in driver_class_list" :key="index" :label="item.dl_class" :value="item.dl_class"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="发证机关" style="width:588px">
					<el-input class="el_inner_width" v-model="form.dl_ca" placeholder="必填" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="有效期开始" style="width:388px">
					<el-date-picker class="el_inner_width" v-model="form.dl_start_date" type="date" placeholder="必填"></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="有效期结束" style="width:588px">
					<el-date-picker class="el_inner_width" v-model="form.dl_end_date" type="date" placeholder="必填"></el-date-picker>
				</el-form-item>
			</div>


			<div class="big_tit" style="display:flex;justify-content: space-between;">
				<div>从业资格证信息</div>
				<div>
					<el-button type="primary" size="mini" style="margin-left:20px" @click="show_tl">显示从业资格证</el-button>
					<el-button type="primary" size="mini" style="margin-left:20px" @click="ocr_tl">验证从业资格证</el-button>
					<el-button type="primary" size="mini" style="margin-left:20px" @click="ocr_tl">识别从业资格证</el-button>
				</div>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="资格证号码" style="width:388px">
					<el-input class="el_inner_width" v-model="form.cy_code" clearable></el-input>
				</el-form-item>
			</div>
		</el-form>

		<div class="bottom_btns">
			<el-button type="primary" @click="save()">仅保存</el-button>
			<el-button type="primary" @click="save_and_upl()">{{upl_btn}}</el-button>
		</div>

		<el-dialog
			top="1vh"
			width="90%"
			:title="img.title"
			append-to-body 
			custom-class="el-dialog-s" 
			:visible.sync="img.is_show"
		>
			<img :src="img.src" style="width:100%">
		</el-dialog>
	</el-dialog>
</template>
<script>
	export default {
		props:{
			is_show:Number,//是否显示
			aim:String,//上报目标
			aim_text:String,//上报目标(注释,用于显示)
			user_num:String,//车牌号
			driver_class_list:Array,//准驾车型列表
		},
		data() {
			return {

				//页面是否显示
				is_show_in_page:false,

				//司机id
				driver_id:'',

				//上报目标
				upl_title:'',

				//上报按钮
				upl_btn:'',

				//表单
				form:{
					app_user_info:{},//用户信息
					dl_class:'',//准驾车型(多个用','隔开)
					dl_ca:'',//驾驶证发证机关
					dl_start_date:'',//驾驶证有效期开始日期
					dl_end_date:'',//驾驶证有效期结束日期
					cy_code:'',//从业资格证号码
				},

				img:{
					is_show:false,
					title:'',
					src:''
				}
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.upl_title=`上报到${this.aim_text}`
					this.upl_btn=`保存并上报到${this.aim_text}`
					this.is_show_in_page=true;
					this.init();
					this.img.is_show=false
				}
			},
		},
		methods: {

			//初始化
			init(){

				//如果没有用户编号则不读取数据
				if(!this.user_num){
					return;
				}

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_driver',
						ctr:'driver_list_by_admin',
						user_num:this.user_num,
					},
					callback:(data)=>{

						//取出数据
						let driver_info=data.list[0]
						this.driver_info=driver_info

						let dl_ocr_rel=JSON.parse(driver_info.dl_ocr_rel)

						this.driver_id=driver_info.id

						//用户数据
						this.form.app_user_info=driver_info.app_user_info

						//准驾车型
						this.form.dl_class=driver_info.dl_class?driver_info.dl_class:dl_ocr_rel.dl_class

						//驾驶证发证机关
						this.form.dl_ca=driver_info.dl_ca?driver_info.dl_ca:dl_ocr_rel.dl_ca

						//驾驶证有效期开始日期
						this.form.dl_start_date=driver_info.dl_start_date>0?new Date(driver_info.dl_start_date*1000):this.get_dl_start_date(dl_ocr_rel.dl_start_date)

						//驾驶证有效期结束日期
						this.form.dl_end_date=driver_info.dl_end_date>0?new Date(driver_info.dl_end_date*1000):this.get_dl_end_date(dl_ocr_rel.dl_end_date)

						//从业资格证信息
						this.form.cy_code=this.form.cy_code?this.form.cy_code:driver_info.app_user_info.id_card_num
					}
				})
			},

			//获取驾驶证有效期结束日期
			get_dl_end_date(dl_end_date){

				if(!dl_end_date){
					return
				}

				dl_end_date=dl_end_date.replace(/-/g,'/'); //必须把日期'-'转为'/'
				return new Date(dl_end_date)
			},

			//获取驾驶证有效期开始日期
			get_dl_start_date(dl_start_date){

				if(!dl_start_date){
					return
				}

				dl_start_date=dl_start_date.replace(/-/g,'/'); //必须把日期'-'转为'/'
				return new Date(dl_start_date)
			},

			//保存并上报
			save_and_upl(){

				//获取表单数据
				let form=this.make_form();
				if(Object.keys(form).length==0){//没有修改
					
					//直接调起上报函数
					this.$my.other.confirm({
						content:"点击'确定'上报此司机",
						confirm:()=>{
							
							//调接口
							this.$my.net.req({
								data:{
									mod:'truck_driver',
									ctr:'driver_upl_by_admin',
									aim:this.aim,
									user_num:this.user_num,
								},
								callback:(data)=>{
									this.$my.other.msg({
										type:'success',
										str:'操作成功'
									});
									this.is_show_in_page=false
									this.$emit('close');
								},
							});
						}
					});
				}else{//有修改

					//保存数据
					this.$my.net.req({
						data:{
							mod:'truck_driver',
							ctr:'driver_edit_by_admin',
							driver_id:this.driver_id,
							...form
						},
						callback:(data)=>{
							
							//直接调起上报函数
							this.$my.other.confirm({
								content:"点击'确定'上报此司机",
								confirm:()=>{
									
									//调接口
									this.$my.net.req({
										data:{
											mod:'truck_driver',
											ctr:'driver_upl_by_admin',
											aim:this.aim,
											user_num:this.user_num,
										},
										callback:(data)=>{
											this.$my.other.msg({
												type:'success',
												str:'操作成功'
											});
											this.is_show_in_page=false
											this.$emit('close');
										},
									});
								}
							});
						},
					});
				}
			},
			save(){//保存

				//获取表单数据
				let form=this.make_form();
				if(Object.keys(form).length==0){
					this.$my.other.msg({
						type:'warning',
						str:'没有修改'
					});
					this.is_show_in_page=false
					this.$emit('close');
					return;
				}
				
				//询问
				this.$my.other.confirm({
					content:"点击'确定'保存数据",
					confirm:()=>{
						
						//调接口
						this.$my.net.req({
							data:{
								mod:'truck_driver',
								ctr:'driver_edit_by_admin',
								driver_id:this.driver_id,
								...form
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.is_show_in_page=false
								this.$emit('close');
							},
						});
					}
				});
			},
			make_form(){//构造被修改过的数据

				let form={};
				let old_data=this.driver_info;
				let new_data=this.form;

				//能源类型
				if(old_data.dl_class!=new_data.dl_class)form.dl_class=new_data.dl_class

				//驾驶证发证机关
				if(old_data.dl_ca!=new_data.dl_ca)form.dl_ca=new_data.dl_ca

				//驾驶证有效期开始日期
				if(new_data.dl_start_date){
					let new_dl_start_date=(new_data.dl_start_date.getTime()/1000).toFixed(0)
					if(old_data.dl_start_date!=new_dl_start_date)form.dl_start_date=new_dl_start_date
				}

				//驾驶证有效期到期日期
				if(new_data.dl_end_date){
					let new_dl_end_date=(new_data.dl_end_date.getTime()/1000).toFixed(0)
					if(old_data.dl_end_date!=new_dl_end_date)form.dl_end_date=new_dl_end_date
				}
				
				//从业资格证号码号
				if(old_data.cy_code!=new_data.cy_code)form.cy_code=new_data.cy_code

				//返回数据
				return form
			},

			//显示驾驶证
			show_dl(){
				this.img.src=this.$my.qiniu.make_src('driverdl',this.driver_info.dl_img),
				this.img.title="驾驶证"
				this.img.is_show=true;
			},

			//识别驾驶证
			ocr_dl(){

				//调用接口
				this.$my.net.req({
					data:{
						mod:'res',
						ctr:'img_ocr',
						img_type:'driver_license',
						img_url:this.$my.qiniu.make_src('driverdl',this.driver_info.dl_img)
					},
					callback:(res)=>{
						this.form.dl_ocr_rel=res
						this.form.dl_class=res.class
						this.form.dl_ca=res.issue_by
						this.form.dl_start_date=this.get_dl_start_date(res.valid_date_start)
						this.form.dl_end_date=this.get_dl_end_date(res.valid_date_end)
					}
				});
			},

			//显示从业资格证号码
			show_tl(){
				this.img.src=this.$my.qiniu.make_src('drivercy',this.driver_info.cy_img),
				this.img.title="从业资格证号码"
				this.img.is_show=true;
			},

			//识别从业资格证号码
			ocr_tl(){

			}
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}
	
	.bottom_btns{
		text-align: center;
		margin-top: 30px;
	}

	.form_ordinary{
		// height: calc(100% - 91px);
		padding: 10px 0;
		overflow-y:auto;
	}

	.el-dialog-s{
		z-index: 11;
	}

</style>