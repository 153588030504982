<template>
	<el-dialog :title="page_title" top="1vh" width="60%" :visible.sync="is_show_in_Page">
		
		<div style="margin-top:-10px;height:500px">
			<el-table :data="list" :border="true" :stripe="true" size="small" height="100%" v-loading="loading">
				<el-table-column label="绑定时间" prop="creat_time_text"></el-table-column>
				<el-table-column label="车队长信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.truck_owner_info.name}} / {{scope.row.truck_owner_info.tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运费分给司机">
					<template slot-scope="scope">
						<div class="tab_line_item">比例:{{scope.row.freight_rate_of_allocated_to_driver_text}} / {{scope.row.freight_allocation_mode_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="状态" prop="status_text"></el-table-column>
				<el-table-column label="本人" prop="is_owner"></el-table-column>
				<el-table-column label="默认" prop="driver_def_text"></el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="110">
					<template slot-scope="scope">
						<el-button v-if="scope.row.status==2 && scope.row.driver_def==2" @click="set_def(scope.row)" size="mini" type="text">设为默认</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</el-dialog>
</template>
<script>
	export default {
		props:{
			driver_num:String,//司机编号
			driver_name:String,//司机姓名
			is_show:Number,//是否显示
		},
		data() {
			return {

				//列表是否显示
				is_show_in_Page:false,

				//上报列表
				list:[],

				//加载状态
				loading: false,//loading特效

				//页面标题
				page_title:'',
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_Page=true;
					this.init();
				}
			},
		},
		methods: {

			//初始化
			init(){

				//如果没有司机编号则不读取数据
				if(!this.driver_num){
					return;
				}

				//列表页初始化
				this.list=[];

				//页面标题
				this.page_title=`${this.driver_name}所在的车队`

				//读取数据
				this.load_data()
			},

			//设为默认
			set_def(item){

				//询问
				this.$my.other.confirm({
					content:`把'${this.driver_name}'的默认车队长设置为'${item.truck_owner_info.name}',请确定`,
					confirm:()=>{
						
						//调接口
						this.$my.net.req({
							data:{
								mod:'truck_driver',
								ctr:'set_def_truck_owner_by_admin',
								driver_num:item.driver_num,
								truck_owner_num:item.truck_owner_num,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});

								//刷新
								this.load_data();
							},
						});
					}
				});
			},

			//读取数据
			load_data(){

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_driver',
						ctr:'driver_bind_list_by_admin',
						driver_num:this.driver_num,
						is_get_truck_owner_info:1
					},
					callback:(data)=>{

						//加载完毕
						this.loading=false

						//预处理
						let list_buf={}
						for(let item of data.list){

							//绑定时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//状态
							switch(item.status){
								
								case '1':item.status_text='已邀请';break;

								case '2':item.status_text='已加入';break;

								case '3':item.status_text='已拒绝';break;
							}

							//是否是默认车队(1:是默认车队,2:不是默认车队)
							switch(item.driver_def){
								
								case '1':item.driver_def_text='是';break;

								case '2':item.driver_def_text='否';break;
							}

							//运单总运费中分给司机的比例(取值0-1)
							item.freight_rate_of_allocated_to_driver_text=`${(item.freight_rate_of_allocated_to_driver*100).toFixed(0)}%`

							//运费分配方式(1:司机优先,2:车队长优先,3:等比例分配)
							switch(item.freight_allocation_mode){
								
								case '1':item.freight_allocation_mode_text='司机优先';break;

								case '2':item.freight_allocation_mode_text='车队长优先';break;

								case '3':item.freight_allocation_mode_text='等比例分配';break;
							}

							//是否是本人
							if(item.truck_owner_num==item.driver_num){
								item.is_owner="是";
							}else item.is_owner="否";
						}

						//渲染
						this.list=data.list
					}
				});
			},
		}
	}
</script>

<style scoped>
	.el-dialog-s{
		z-index: 11;
	}
</style>