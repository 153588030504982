<template>
	<el-dialog title="司机上报列表" top="1vh" width="60%" :visible.sync="is_show_in_Page">
		
		<div style="margin-top:-10px;height:500px">
			<el-table :data="upl_list" :border="true" :stripe="true" size="small" height="100%" v-loading="loading">
				<el-table-column label="上报目标" prop="aim_text"></el-table-column>
				<el-table-column label="司机信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.app_user_info.name}} / {{scope.row.app_user_info.tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="上报时间" prop="creat_time_text" width="190"></el-table-column>
				<el-table-column label="操作人">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.admin_user_info.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="状态" prop="status_text"></el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="110">
					<template slot-scope="scope">
						<el-button v-if="scope.row.status==0" @click="upl_view_open(scope.row)" size="mini" type="text">上报</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 上报界面 -->
		<UplView 
			:is_show="upl.is_show" 
			:aim="upl.aim" 
			:aim_text="upl.aim_text" 
			:user_num="user_num" 
			:driver_class_list="driver_class_list" 
			@close="upl_view_close"
		></UplView>

	</el-dialog>
</template>
<script>
	import UplView from './upl_view.vue'
	import {mapState} from 'vuex'
	export default {
		components:{
			UplView,
		},
		props:{
			user_num:String,//用户编号
			is_show:Number,//是否显示
		},
		data() {
			return {

				//列表是否显示
				is_show_in_Page:false,

				//上报列表
				upl_list:[],

				//加载状态
				loading: false,//loading特效

				//准驾车型列表
				driver_class_list:[],

				//上报界面
				upl:{
					is_show:0,
					aim:'',
					aim_text:''
				}
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_Page=true;
					this.init();
				}
			},
		},
		computed:{
			...mapState(['user_info'])
		},
		methods: {

			//初始化
			init(){

				//如果没有司机编号则不读取数据
				if(!this.user_num){
					return;
				}

				//列表页初始化
				this.upl_list=[];

				//读取数据
				this.load_data()
			},

			//打开上报界面
			upl_view_open(item){
				this.upl.aim_text=item.aim_text
				this.upl.aim=item.aim
				this.upl.is_show++;
			},
			upl_view_close(){
				this.upl.is_show=0;
				this.init();
			},

			//读取数据
			load_data(){

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_driver',
						ctr:'driver_upl_list_by_admin',
						user_num:this.user_num
					},
					callback:(data)=>{

						//加载完毕
						this.loading=false

						//预处理
						let upl_list_buf={}
						for(let upl_item of data.list){

							//上报时间
							upl_item.creat_time_text=this.$my.other.totime(upl_item.creat_time);

							//上报目标
							upl_item.aim_text=data.upl_aim_list[upl_item.aim]

							//绑定app用户信息
							upl_item.app_user_info=data.app_user_info

							//状态
							switch(upl_item.status){
								
								//上报中
								case '1':upl_item.status_text='上报中';break;

								//上报成功
								case '2':upl_item.status_text='上报成功';break;

								//上报失败
								case '3':upl_item.status_text='上报失败';break;
							}

							upl_list_buf[upl_item.aim]={...upl_item}
						}

						//补充未上报
						for(let upl_aim_item in data.upl_aim_list){
							
							if(!upl_list_buf[upl_aim_item]){
								upl_list_buf[upl_aim_item]={
									creat_time_text:'未上报',
									event_num:'',
									user_num:this.user_num,
									app_user_info:data.app_user_info,
									admin_user_num:'',
									admin_user_info:{
										name:''
									},
									aim:upl_aim_item,
									aim_text:data.upl_aim_list[upl_aim_item],
									status:0,
									status_text:'未上报',
									send_data:'',
									return_data:'',
								};
							}
						}
						
						//渲染
						this.upl_list=Object.values(upl_list_buf)
						this.driver_class_list=data.driver_class_list
					}
				});
			},
		}
	}
</script>

<style scoped>
	.el-dialog-s{
		z-index: 11;
	}
</style>