import { render, staticRenderFns } from "./truck_owner_list.vue?vue&type=template&id=ecde5c4c&scoped=true&"
import script from "./truck_owner_list.vue?vue&type=script&lang=js&"
export * from "./truck_owner_list.vue?vue&type=script&lang=js&"
import style0 from "./truck_owner_list.vue?vue&type=style&index=0&id=ecde5c4c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecde5c4c",
  null
  
)

export default component.exports